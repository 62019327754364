<template>
<div class="picBox">
	<vue-preview :slides="details" class="preview" style="display:inline-block;"></vue-preview>
</div>
</template>

<script>
import deviceImg from "../assets/home/device.png";
import smartImg from "../assets/home/smart.png";
import alarmImg from "../assets/home/alarm.png";
import systemImg from "../assets/home/system.png";
export default {
    data() {
        return {
           details: [],
        };
    },
    mounted(){
        this.getThirdHaikEventList();
    },
    methods: {
     getThirdHaikEventList() {
       let data = [{imageUrl:deviceImg},{imageUrl:smartImg},{imageUrl:alarmImg},{imageUrl:systemImg},]
            if (data && Object.keys(data).length) {
				data.forEach(item => {
					item.w = 460;   //设置以大图浏览时的宽度
                    item.h = 440;     //设置以大图浏览时的高度
                    item.src = item.imageUrl;  //大图
                    item.msrc = item.imageUrl;  //小图
				})
				this.details = data;
            }
		},
    },  
};
</script>
<style lang="scss" >
img {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}
.picBox{
    background-color: black;
    width:28vw;
    overflow: hidden;
}
.preview{
    width:30vw;
    height: 50vh; 
    overflow-y: scroll;
}
.preview figure {
	float: left;
	width: 30%;
	height:auto;
	margin: 1.5% 0;
}
.pswp__button--share{
    display: none !important;
} 
.preview figure img {
	width: 100%;
	height: 75%;
}
</style>